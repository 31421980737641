import React from 'react';

const year = new Date().getFullYear();

export function Footer() {
  return (
      <footer className="app-footer">
          <div className="">
              <hr/>
              <div className="col-12">
                  &copy; 2018 - {year} Seccubi, All rights reserved.
              </div>
          </div>
      </footer>
  );
}
