import React, {Component} from 'react';
import {connect} from 'react-redux';
import {createUser, updateUser, deleteUser, users, getLocalStorageObject} from './actions/actions';
import DataTable from 'react-data-table-component';
import {errorHandler} from './actions/SwalActions';
import Swal from 'sweetalert2'
import { Modal, Button, Alert } from "react-bootstrap";
import Select from 'react-select';
import moment from "moment-timezone";
// import IconButton from '@material-ui/core/IconButton';

import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { reducer as form } from 'redux-form';
import {
  connectRouter,
  routerMiddleware,
} from 'connected-react-router';
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();
const store = createStore(
  combineReducers({
    router: connectRouter(history),
    form,
    /* Add your reducers here */
  }),
  applyMiddleware(routerMiddleware(history), thunk)
);

const ROWS_PER_PAGE = 'users/currentRowsPerPage';
const SORT_DIRECTION = 'users/sortDirection';
const timezones = moment.tz.names().map((timezone) => {
  return {value: timezone, label: timezone}
});
let me = null;
const roleNames = {
  ROLE_ADMIN: 'Administrator',
  ROLE_USER: 'User',
};

class Users extends Component {

  state = {
    show: false,
    users: [],
    initialLoad: true,
    user_email: '',
    roles: ['ROLE_USER'],
    user_id: null,
    user_timezone: me ? me.timezone : null,
    userSaved: '',
    preventEditEmail: true,
  };

  handleCreate = () => {
    this.setState({
      show: true,
      user_email: '',
      user_timezone: me ? me.timezone : null,
      user_id: null,
      preventEditEmail: false,
      roles: ['ROLE_USER'],
    });
  }

  handleUpdate = (user) => {
    console.log(user);
    this.setState({
      show: true,
      user_email: user.email,
      user_timezone: user.timezone,
      user_id: user.id,
      preventEditEmail: true,
      roles: user.roles,
    });
  }

  handleDelete = (user) => {
    Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to delete this item? "+user.email+"? This operation cannot be undone. ",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        closeOnConfirm: false,
        closeOnCancel: false
      }).then((submit) => {
      if (submit.isConfirmed) {
        store.dispatch(deleteUser(user.id)).then(data => {
          if (data) {
            this.props.isLoggedIn(data);
            if (data.type === "error") {
              errorHandler(data.message);
            } else {
              const users = this.state.users;
              this.setState({
                users: users.filter(item => item.id !== user.id),
                userSaved: 'User successfully deleted',
              });
            }
          }
        });
      }
    });

    // this.setState({
    //   show: true,
    //   user_email: '',
    //   user_id: null,
    // });
  }
  handleTimezoneChange = (data) => {
    this.setState({
      user_timezone: data.value,
    });
  }

  handleClose = () => {
    this.setState({
      show: false,
      roles: ['ROLE_USER'],
    });
  }

  handleSave = () => {
    const {user_email, user_id, user_timezone, roles} = this.state;
    //update
    const callback = new Promise((resolve, reject) => {
      if (user_id !== null) {
        store.dispatch(updateUser({
          id: user_id,
          timezone: user_timezone,
          roles: roles,
        })).then(data => {
          if (!data) {
            reject("Unexpected exception during updating the user");
            return;
          }
          this.props.isLoggedIn(data);
          if (data.type === "error") {
            reject(data.message);
            return;
          }
          const users = this.state.users;
          for (let i in users) {
            if (users[i].id === user_id) {
              users[i] = data.data;
              break;
            }
          }
          resolve({
            userSaved: 'User successfully updated',
            users: users,
          });
        });
        return;
      }
      //create
      store.dispatch(createUser({
        email: user_email,
        timezone: user_timezone,
        roles: roles,
      })).then(data => {
        if (!data) {
          reject("Unexpected exception during creating the user");
          return;
        }
        this.props.isLoggedIn(data);
        if (data.type === "error") {
          reject(data.message);
          return;
        }
        const users = this.state.users;
        users.push(data.data);

        resolve({
          userSaved: 'User successfully created',
          users: users,
        });
      });
    });
    callback.then((data) => {
      this.handleClose();
      this.setState(data);
      setTimeout(() => this.setState({'userSaved': ''}),2000);
    }).catch(message => errorHandler(message));
  }


  componentDidMount = () => {
    me = getLocalStorageObject('user');
    store.dispatch(users()).then(resp => {
      this.props.isLoggedIn(resp);
      if (resp.type === 'error') {
        return false;
      }
      this.setState({
        users: Array.isArray(resp.data) ? resp.data : [],
        initialLoad: false,
      });
    });
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  saveRoles = event => {
    const {roles} = this.state;
    const item = event.target.value;
    const index = roles.indexOf(item);
    if (event.target.checked) {
      if (index === -1) {
        roles.push(item);
      }
    } else {
      if (index !== -1) {
        roles.splice(index, 1);
      }
    }
    this.setState({
      roles: roles,
    })
  };

  render() {

    const { initialLoad, user_timezone, roles } = this.state;
    const defaultTimezone = timezones.findIndex(timezone => timezone.value === user_timezone);
    const columns = [
      {
        name: 'Email',
        selector: 'email',
        sortable: true,
        cell:(user) => <span className={ user.id === me.id ? 'you' : '' }>{user.email}</span>
      },
      {
        name: 'Roles',
        selector: 'roles',
        cell:(user) => user.roles.map(role => roleNames[role]).join(', ')
      },
      {
        cell:(user) =>
          me && me.roles.indexOf("ROLE_ADMIN") === -1 ? '' :
          <>
            <Button className="shadow-none"  onClick={() => this.handleUpdate(user)} variant="link">
              <i className="fa fa-edit"/>
            </Button>
            <Button className="shadow-none" onClick={() => this.handleDelete(user)} variant="link">
              <i className={'fa fa-trash text-danger ' + ( user.id !== me.id || 'invisible' )}/>
            </Button>
          </>,
       name: me && me.roles.indexOf("ROLE_ADMIN") === -1 ? '' :
          <Button variant="primary" onClick={this.handleCreate}>
             Add User
           </Button>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ];

    const editUserId = this.state.user_id;
    return (
      <div>
        <h2>Users</h2>

        {initialLoad ?
          <div className="col-12 text-center">
            <div className="spinner-border text-center"/>
          </div>
          :
          <>
            <Alert show={this.state.userSaved.length > 0} variant="success">
              {this.state.userSaved}
            </Alert>
            <Modal show={this.state.show} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {editUserId === null ? 'Create User' : 'Edit User'}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                <div className="form-group">
                  <label htmlFor="user-email">Email:</label>
                  <input
                    placeholder="Enter email"
                    className="form-control"
                    id="user-email"
                    name="user_email"
                    value={this.state.user_email}
                    onChange={this.handleChange}
                    disabled={this.state.preventEditEmail}
                  />
                </div>
                <div className="form-group">
                  <label>Roles:</label>

                  <div className="form-group m-0">
                      <input
                        id="role-admin"
                        value="ROLE_ADMIN"
                        onChange={this.saveRoles}
                        type="checkbox"
                        checked={roles.indexOf('ROLE_ADMIN') !== -1}
                      /> <label htmlFor="role-admin">Administrator</label>
                    </div>
                  <div className="form-group m-0">
                      <input
                        id="role-user"
                        value="ROLE_USER"
                        onChange={this.saveRoles}
                        type="checkbox"
                        checked={roles.indexOf('ROLE_USER') !== -1}
                      /> <label htmlFor="role-user">User</label>
                  </div>
                </div>

                  <div className="form-group">
                    <label htmlFor="user-timezone">Timezone:</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isSearchable={true}
                      name="timezone"
                      options={timezones}
                      onChange={this.handleTimezoneChange}
                      defaultValue={timezones[defaultTimezone]}
                    />
                  </div>

                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={this.handleSave}>
                  {editUserId === null ? 'Create' : 'Save'}
                </Button>
              </Modal.Footer>
            </Modal>

            <DataTable
              columns={columns}
              data={this.state.users}
              pagination={true}
              onChangeRowsPerPage={(currentRowsPerPage) => {
                localStorage.setItem(ROWS_PER_PAGE,currentRowsPerPage);
              }}
              paginationPerPage={localStorage.getItem(ROWS_PER_PAGE) || 10}
              onSort={(field, direction) => {
                localStorage.setItem(SORT_DIRECTION,direction)
              }}
              defaultSortAsc={localStorage.getItem(SORT_DIRECTION) !== 'desc'}
            />
          </>
        }
      </div>
    )
  }
}

export default connect((state) => ({
  users: state.users,
  show: state.show,
  initialLoad: state.initialLoad,
  userSaved: state.userSaved,
  user_timezone: state.user_timezone,
  roles: state.roles,
}))(Users);
