import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom'
import {verify} from './actions/actions';
import {errorHandler, successHandler} from './actions/SwalActions';


import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { reducer as form } from 'redux-form';
import {
  connectRouter,
  routerMiddleware,
} from 'connected-react-router';
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();
const store = createStore(
  combineReducers({
    router: connectRouter(history),
    form,
    /* Add your reducers here */
  }),
  applyMiddleware(routerMiddleware(history), thunk)
);

class Verify extends Component {
  componentDidMount = () => {
    store.dispatch(verify(this.props.match.params.id, this.props.match.params.token)).then(resp => {
      const data = resp.data;
      const redirect = () => this.setState({'fireRedirect': true});
      if (resp.type === 'error') {
        errorHandler(data.message, redirect);
        return false;
      }
      successHandler("Thank you! Your email has been verified.", redirect);
    });
  }

  state = {
    fireRedirect: false,
  };

  render() {
    const { fireRedirect } = this.state
    return (
      <>
        {fireRedirect ?
          <Redirect to="/login" />
          :
          <div className="row">
            <div className="col-12 text-center">
              <div className="spinner-border text-center"/>
            </div>
          </div>
        }
      </>
    )
  }
}

export default connect((state) => ({
  fireRedirect: state.fireRedirect,
}))(Verify);
