import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from "moment-timezone";
import { NavLink } from 'react-router-dom'
import {loginUser, signUp} from './actions/actions';
import { Redirect } from 'react-router'

import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { reducer as form } from 'redux-form';
import {
  connectRouter,
  routerMiddleware,
} from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { errorHandler } from "./actions/SwalActions";
const history = createBrowserHistory();
const store = createStore(
  combineReducers({
    router: connectRouter(history),
    form,
    /* Add your reducers here */
  }),
  applyMiddleware(routerMiddleware(history), thunk)
);

class Signup extends Component {

  state = {
    company: "",
    email: "",
    password: "",
    password_confirm: "",
    fireRedirect: false,
  };


  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = event => {
    event.preventDefault();

    const { password, password_confirm, email } = this.state;

    if (password !== password_confirm) {
      errorHandler('Passwords don\'t match');
      return;
    }

    if (password.length < 10) {
      errorHandler('Password should be at least 10 characters long');
      return;
    }

    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!pattern.test(email)) {
      errorHandler('Please enter valid Email address');
      return;
    }

    const companyInfo = {
      name: this.state.company,
      users: [{
        email: this.state.email,
        password: this.state.password,
        timezone: moment.tz.guess(),
      }],
    };

    store.dispatch(signUp(companyInfo)).then(data => {
      if (data !== undefined && data.type === 'success') {
        store.dispatch(loginUser(companyInfo.users[0])).then(data => {
          if (data !== undefined && data.type === 'success') {
            this.setState({'fireRedirect': true});
          }
        })
      } else {
        errorHandler(data.message);
      }
    });
  }

  render() {
    const { fireRedirect } = this.state
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email address</label>
            <input
              className="form-control"
              name='email'
              id='email'
              placeholder='Enter email'
              value={this.state.email}
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="company">Company</label>
            <input
              className="form-control"
              name='company'
              id='company'
              placeholder='What is your company name?'
              value={this.state.company}
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              className="form-control"
              name='password'
              id='password'
              placeholder='Password'
              value={this.state.password}
              onChange={this.handleChange}
              required
              type="password"
            />
          </div>
          <div className="form-group">
            <label htmlFor="password_confirm">Password Confirmation</label>
            <input
              className="form-control"
              name='password_confirm'
              id='password_confirm'
              placeholder='Confirm your password'
              value={this.state.password_confirm}
              onChange={this.handleChange}
              required
              type="password"
            />
          </div>
          <div className="form-group">
            <input
              name='terms'
              value="1"
              onChange={this.accept}
              required
              type="checkbox"
            /> <label htmlFor="terms">I agree to <a href="/terms" target="_blank"><u>terms and conditions</u></a></label>
          </div>



          <button type="submit" className="btn btn-primary">Sign up</button>

        </form>
        <div>
          <div>
            Already have an account? <NavLink to="/login" exact>
              Sign in
            </NavLink>
          </div>
        </div>
        {fireRedirect && (<Redirect to='/' />)}
      </div>
    )
  }
}

export default connect((state) => ({
  fireRedirect: state.fireRedirect
}))(Signup);
