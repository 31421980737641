import Swal from 'sweetalert2'

const fire = (message, cb, type) => {
  const promise = Swal.fire({
    icon: type,
    text: message,
  });
  if (cb) {
    promise.then(cb);
  }
}

export const errorHandler = (message, cb) => {
  fire(message, cb, 'error');
}
export const successHandler = (message, cb) => {
  fire(message, cb, 'success');
}
