import React, {Component} from 'react';
import {connect} from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom'
import {resetToken, doReset} from './actions/actions';
import { Redirect } from 'react-router'

import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { reducer as form } from 'redux-form';
import {
  connectRouter,
  routerMiddleware,
} from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { errorHandler } from "./actions/SwalActions";
const history = createBrowserHistory();
const store = createStore(
  combineReducers({
    router: connectRouter(history),
    form,
    /* Add your reducers here */
  }),
  applyMiddleware(routerMiddleware(history), thunk)
);

class Reset extends Component {

  state = {
    token: "",
    password: "",
    password_confirm: "",
    fireRedirect: false,
    loading: true,
  };

  componentDidMount = () => {
    const { match: { params } } = this.props;

    store.dispatch(resetToken(params.token)).then(resp => {
      if (resp.type !== 'error') {
        this.setState({
          token: params.token,
        });
      }
      this.setState({
        loading: false
      });
    });
  }


  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = event => {
    event.preventDefault();

    const { password, password_confirm } = this.state;

    if (password !== password_confirm) {
      errorHandler('Passwords don\'t match');
      return;
    }

    store.dispatch(doReset(this.state)).then((resp) => {
      if (resp.type === "success") {
        this.setState({
          fireRedirect: true,
        });
      }
    });
  }

  render() {
    const { fireRedirect, token, loading } = this.state
    return (
      <div>
        {loading ?
          <div className="row">
            <div className="col-12 text-center">
              <div className="spinner-border text-center"/>
            </div>
          </div>
          :
          <>
            { token ?
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    className="form-control"
                    name='password'
                    id='password'
                    placeholder='Password'
                    value={this.state.password}
                    onChange={this.handleChange}
                    required
                    type="password"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password_confirm">Password Confirmation</label>
                  <input
                    className="form-control"
                    name='password_confirm'
                    id='password_confirm'
                    placeholder='Confirm your password'
                    value={this.state.password_confirm}
                    onChange={this.handleChange}
                    required
                    type="password"
                  />
                </div>

                <button type="submit" className="btn btn-primary">Submit</button>

              </form>
              :
              <div className="alert alert-danger mt-1" role="alert">
                No such token, or it has been expired
              </div>
            }
            <div>
              <div>
                <NavLink to="/login" exact>
                  Sign in
                </NavLink>
              </div>
            </div>
          </>
        }
        {fireRedirect && (<Redirect to='/' />)}
      </div>
    )
  }
}

export default withRouter(connect((state) => ({
  fireRedirect: state.fireRedirect,
  token: state.token,
  loading: false,
}))(Reset));
