import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    assets,
    getLocalStorageObject,
    metricsCompany,
    metricsDaily,
    metricsInstant,
} from './actions/actions';
import Select from 'react-select';
import moment from "moment-timezone";
// import IconButton from '@material-ui/core/IconButton';
import {
    FlexibleWidthXYPlot,
    Hint,
    LineMarkSeries,
    VerticalGridLines,
    HorizontalGridLines,
    XAxis,
    YAxis,
    VerticalBarSeries
} from 'react-vis';
import {createStore, combineReducers, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {reducer as form} from 'redux-form';
import {
    connectRouter,
    routerMiddleware,
} from 'connected-react-router';
import {createBrowserHistory} from 'history';

const history = createBrowserHistory();
const store = createStore(
    combineReducers({
        router: connectRouter(history),
        form,
        /* Add your reducers here */
    }),
    applyMiddleware(routerMiddleware(history), thunk)
);

function formatBytes(a, b = 2) {
    if (0 === a) return "0 B";
    const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1024));
    return Math.floor(parseFloat((a / Math.pow(1024, d)).toFixed(c)), b) + " " + ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
}

function formatKs(a, b = 2) {
    if (0 === a) return "0";
    const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1000));
    return Math.floor(parseFloat((a / Math.pow(1000, d)).toFixed(c)), b) + " " + ["", "K", "M", "B"][d]
}

class Dashboard extends Component {
    state = {
        assetsList: [],
        company_daily: [],
        daily: [],
        instant: [],
        asset_id: null,
        company: null,
        timer: null,
        dataPoints0: {
            value: null,
            index: null,
            x: 0,
            y: 0,
        },
        dataPoints1: {
            value: null,
            index: null,
            x: 0,
            y: 0,
        },
        dataPoints2: {
            value: null,
            index: null,
            x: 0,
            y: 0,
        },
    };

    componentDidMount = () => {
        const company = getLocalStorageObject('company');
        const assetPromise = store.dispatch(assets());
        const companyPromise = store.dispatch(metricsCompany());

        Promise.all([assetPromise, companyPromise]).then(values => {
            this.props.isLoggedIn(values[0]);
            for (const i in values) {
                if (values.hasOwnProperty(i) && values[i].type === 'error') {
                    return false;
                }
            }
            this.setState({
                assetsList: values[0] ? values[0].data : [],
                company: company,
                company_daily: values[1] ? values[1].data : [],
            });
        });
    }

    handleChangeAsset = event => {
        const assetId = event.value;
        const metricsD = store.dispatch(metricsDaily(assetId));
        const metricsI = store.dispatch(metricsInstant(assetId));

        Promise.all([metricsD, metricsI]).then(values => {
            this.props.isLoggedIn(values[0]);
            for (const i in values) {
                if (values.hasOwnProperty(i) && values[i].type === 'error') {
                    return false;
                }
            }
            this.setState({
                daily: values[0] ? values[0].data : [],
                instant: values[1] ? values[1].data : [],
                asset_id: assetId,
            }, this.realtime);
        });
    }


    realtime = () => {
        const {asset_id, timer} = this.state;
        store.dispatch(metricsInstant(asset_id)).then(resp => {
            if (resp) {
                this.props.isLoggedIn(resp);
                this.setState({
                    instant: resp ? resp.data : [],
                });
            }
            clearTimeout(timer);
            this.setState({
                timer: setTimeout(this.realtime, 5000),
            });
        });
    }

    tickFormatterDay = value => moment(value).format("MMM D");
    tickFormatterHour = value => moment(value).format("HA");
    tickFormatterSecond = value => moment(value).format("m:ss");

    renderChart = (series, chartType, type, datapoint, isHalf) => {
        const ticks = window.innerWidth < 700 ?  (isHalf ? 2 : 3) : (isHalf ? 4 : 7);
        if (series.length === 0) {
            return <h5 className="text-center">No data available</h5>;
        }
        const data = series.reduce((accumulator, value) => {
            const x = moment(value.day || value.hour || value.time);
            accumulator.bytes.push({
                x: x,
                y: value.bytes,
                label: 'x',
            });
            accumulator.entries.push({
                x: x,
                y: value.entries,
                label: 'x',
            });
            return accumulator;
        }, {bytes: [], entries: []});


        if (data.bytes.length === 0) {
            const time = type === 'day' ? moment().subtract(30, 'days')
                : (type === 'hour' ? moment().subtract(10, 'hours')
                    : moment().subtract(1, 'minutes'));
            data.bytes.push({
                x: time,
                y: 0,
            });
            data.entries.push({
                x: time,
                y: 0,
            });
        }
        if ((type === 'day' && data.bytes[data.bytes.length - 1].x.diff(moment(), 'days') < -1)
            || (type === 'time' && data.bytes[data.bytes.length - 1].x.diff(moment(), 'seconds') < -10)) {

            data.bytes.push({
                x: moment(),
                y: 0,
            });
            data.entries.push({
                x: moment(),
                y: 0,
            });
        }

        const formatter = type === 'day' ? this.tickFormatterDay
            : type === 'hour' ? this.tickFormatterHour : this.tickFormatterSecond;
        const height = 300;

        // Function to convert scale to another axis Y
        const handleCustomScale = (tick) => { // Can be add a customStart parameter to sum with result
            //                                     if scales doesn't start at same point
            const maxSalesValue = Math.max(...data.bytes.map(item => item.y)); // Get max value from sales data
            const maxPriceValue = Math.max(...data.entries.map(item => item.y)); // Get max value from prices data
            const factor = maxSalesValue / maxPriceValue; // Calculate factor to convert sales tick to price tick
            return formatKs(Math.round(tick / factor)); // Return result as a integer
        }

        return (
            <FlexibleWidthXYPlot height={height} margin={{right: 100, left: 100}} stackBy="y">
                <VerticalBarSeries
                    barWidth={0.5}
                    data={data.bytes}
                    color='#080072'
                />
                <LineMarkSeries
                    yDomain={[0, Math.max(...data.entries.map(item => item.y))]} // Get the max value of the line data
                    data={data.entries}
                    style={{fill: 'none'}}
                    color='#007bff'
                    onNearestXY={(value, {event, innerX, innerY, index}) => {
                        const obj = {};
                        obj[chartType] = {
                            value: value,
                            x: innerX,
                            y: innerY,
                            index: index,
                        };
                        this.setState(obj);
                    }}
                />
                <VerticalGridLines/>
                <HorizontalGridLines/>
                <XAxis tickTotal={ticks} tickFormat={formatter}/>
                <YAxis title="Bytes" tickFormat={formatBytes}
                       style={{text: {fill: '#080072'}, title: {fill: '#080072'}}}/>
                <YAxis orientation='right' tickTotal={5} tickFormat={v => handleCustomScale(v)} title="Count"
                       style={{text: {fill: '#007bff'}, title: {fill: '#007bff'}}}/>
                <Hint style={{"marginLeft": datapoint.x - 150, "marginBottom": height - 50 - parseInt(datapoint.y)}}
                      value={data.entries[0]}>
                    <h1 hidden={!datapoint.value} className="text-white badge bg-info">
                        <div>{datapoint.value ? formatter(datapoint.value.x) : ''}:</div>
                        <div>Entries count: {datapoint.value ? formatKs(datapoint.value.y) : ''}</div>
                        <div>Data
                            volume: {data.bytes[datapoint.index] && data.bytes[datapoint.index].y ? formatBytes(data.bytes[datapoint.index].y) : formatBytes(0)}</div>
                    </h1>
                </Hint>
            </FlexibleWidthXYPlot>
        );


    };

    render() {
        let {
            company_daily,
            daily,
            instant,
            asset_id,
            company,
            assetsList,
            dataPoints0,
            dataPoints1,
            dataPoints2
        } = this.state;

        if (window.innerWidth < 700) {
            company_daily = company_daily.slice(-10);
            daily = daily.slice(-10);
        }

        if (company === null) {
            return <div className="col-12 text-center">
                <div className="spinner-border text-center"/>
            </div>;
        }

        const unBilled = company_daily
            .reduce((accumulator, value) => {
                accumulator.bytes += value.bytes;
                accumulator.entries += value.entries;
                return accumulator;
            }, {bytes: 0, entries: 0});
        const assetOptions = assetsList.map((item) => {
            return {value: item.id, label: item.name}
        });
        return (
            <>
                <h2>Dashboard</h2>
                <div className="row">
                    <div className="col-md-8 col-sm-12">
                        <h5 className="text-info">Company logs</h5>
                        {this.renderChart(company_daily, 'dataPoints0', 'day', dataPoints0, false)}
                    </div>
                    <div className="col-md-4 col-sm-12 text-center">
                        <div className="row">
                            <h5 className="text-info col-12">Total data last {company_daily.length} days</h5>
                            <small className="col-12 text-muted">The numbers do not reflect Today's data</small>
                        </div>
                        <div className="row mt-5">
                            <div className="col-6 text-primary">
                                <h1 className="fas fa-database"/>
                                <h1>{formatBytes(unBilled.bytes)}</h1>
                                <h6>data collected</h6>
                            </div>
                            <div className="col-6 text-primary">
                                <h1 className="fab fa-buffer"/>
                                <h1>{formatKs(unBilled.entries)}</h1>
                                <h6>log entries</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 mb-5 mt-5">
                        <div><label htmlFor="asset">Asset:</label></div>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            name="asset_id"
                            id="asset_id"
                            options={assetOptions}
                            onChange={this.handleChangeAsset}
                        />
                    </div>
                </div>
                {asset_id ? <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <h5 className="text-info">Usage daily</h5>
                        {this.renderChart(daily, 'dataPoints1', 'day', dataPoints1, true)}
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <h5 className="text-info">Usage realtime</h5>
                        {this.renderChart(instant, 'dataPoints2', 'second', dataPoints2, true)}
                    </div>
                </div> : ''}
            </>
        )
    }
}

export default connect((state) => ({
    company_daily: state.company_daily,
    daily: state.daily,
    instant: state.instant,
    asset_id: state.asset_id,
    assetsList: state.assetsList,
    dataPoints: state.dataPoints,
}))(Dashboard);
