import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { reducer as form } from 'redux-form';
import { Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ErrorBoundary } from './ErrorBoundary';
import {
  ConnectedRouter,
  connectRouter,
  routerMiddleware
} from 'connected-react-router';
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';
import * as serviceWorker from './serviceWorker';
import CookieConsent from "react-cookie-consent";
// Import your reducers and routes here

/** CSS **/
import './styles/styles.sass';

/** Layouts **/
import MainLayout from "./layouts/main-layout"
import LoginLayout from "./layouts/login-layout"

import Signup from './Signup';
import Login from './Login';
import Users from './Users';
import Assets from './Assets';
import Logs from './Logs';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import Forgot from "./Forgot";
import Reset from "./Reset";
import Verify from "./Verify";
import Demo from "./Demo";
import Dashboard from "./Dashboard";
import {Terms} from "./Terms";
import Asset from "./Asset";
// import Demo from "./Demo";

const history = createBrowserHistory();
const store = createStore(
  combineReducers({
    router: connectRouter(history),
    form,
    /* Add your reducers here */
  }),
  applyMiddleware(routerMiddleware(history), thunk)
);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ErrorBoundary>
      <Switch>
        <PrivateRoute
          exact path="/"
          component={props => (
            <MainLayout>
              <Dashboard {...props} />
            </MainLayout>
          )}
        />
        <PublicRoute
          exact path="/forgot"
          component={props => (
            <LoginLayout>
              <Forgot {...props} />
            </LoginLayout>
          )}
        />
        <Route
          exact path="/users/:id/verify/:token"
          component={props => (
            <LoginLayout>
              <Verify {...props} />
            </LoginLayout>
          )}
        />
        <PublicRoute
          exact path="/signup"
          component={props => (
            <LoginLayout>
              <Signup {...props} />
            </LoginLayout>
          )}
        />
        <PublicRoute
          exact path="/login"
          component={props => (
            <LoginLayout>
              <Login {...props} />
            </LoginLayout>
          )}
        />
        <PublicRoute
          exact path="/reset/:token"
          component={props => (
            <LoginLayout>
              <Reset {...props} />
            </LoginLayout>
          )}
        />
        <Route
          exact path="/terms"
          component={() => (
            <LoginLayout>
              <Terms/>
            </LoginLayout>
          )}
        />

        <PrivateRoute
          path='/logs'
          component={props => (
            <MainLayout>
              <Logs {...props} />
            </MainLayout>
          )}
        />

        <PrivateRoute
          path='/users'
          component={props => (
            <MainLayout>
              <Users {...props} />
            </MainLayout>
          )}
        />


        <PrivateRoute
          path='/assets'
          component={props => (
            <MainLayout>
              <Assets {...props} />
            </MainLayout>
          )}
        />


        <PrivateRoute
          path='/asset/create'
          component={props => (
            <MainLayout>
              <Asset {...props} />
            </MainLayout>
          )}
        />

        <PrivateRoute
          path='/asset/:id'
          component={props => (
            <MainLayout>
              <Asset {...props} />
            </MainLayout>
          )}
        />


        <Route
          path='/demo'
          component={() => (
              <Demo />
          )}
        />

        <Route render={() => <h1>Not Found</h1>} />
      </Switch>
      </ErrorBoundary>
    </ConnectedRouter>
    <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
