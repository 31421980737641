import React from 'react';
import { Button } from "react-bootstrap";

export default class TagList extends React.Component {
  render(){
    const onclick = this.props.onClick;
    return (
      <div className="row">
        <div className="col-12">
          { this.props.data.map(function(item, i) {
            return <Button key={i} onClick={() => onclick(item)} variant="link"
              className={"shadow-none mx-1 text-white badge bg-" + (item.type === 'ip' || item.type === 'ipport' ? 'primary' : (item.type === 'custom_tag' ? 'secondary' : 'info'))}
              >{item.value}</Button>
          })
          }
        </div>
      </div>);
  }
}
