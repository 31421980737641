import * as React from 'react';
import { Helmet } from 'react-helmet';

export function Links() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8"/>
          <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
          <meta name="viewport" content="width=device-width, initial-scale=1"/>
          <link rel="icon" type="image/png"
                href="/icon.png"
                sizes="16x16"/>
          <title>Seccubi</title>
        </Helmet>
      </div>
    );
}
