import { errorHandler } from './SwalActions';
import { ENTRYPOINT } from '../config/entrypoint'


const genericErrorHandler = () => {
  errorHandler('Our engineers are working to resolve the issue. Thank you for your patience');
}

const hashCode = (s) => {
  return s.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);
}

let cache = {};

export const setLocalStorageObject = (key, obj) => {
    localStorage.setItem(key, JSON.stringify(obj));
}

export const getLocalStorageObject = (key) => {
  return JSON.parse(localStorage.getItem(key));
}

function objToQueryString(obj) {
  const keyValuePairs = [];
  for(const key in obj) {
    if (!obj.hasOwnProperty(key)) {
      continue;
    }
    const value = obj[key];
    const keyEncoded = encodeURIComponent(key);
    if (Array.isArray(value)) {
      for(const key2 in value) {
        if (!value.hasOwnProperty(key2)) {
          continue;
        }
        keyValuePairs.push(`${keyEncoded}[]=${encodeURIComponent(value[key2])}`);
      }
      continue;
    }
    keyValuePairs.push(`${keyEncoded}=${encodeURIComponent(obj[key])}`);
  }
  return keyValuePairs.join('&');
}

const request = (url, method, data, header) => {
  url = ENTRYPOINT+'/' + url;
  if (header === undefined) {
    header = {
    };
  }
  if (method === 'PATCH') {
    header['Content-Type'] = 'application/merge-patch+json';
    // header['Accept'] = 'application/merge-patch+json';
  } else {
    header['Content-Type'] = 'application/json';
  }
  header['Accept'] = 'application/json';

  // header['withCredentials'] = true;
  const params = {
    method: method,
    headers: header,
    credentials: 'include',
  };

  if (data) {
    if (method === 'GET') {
      url += '?' + objToQueryString(data);
    } else {
      params.body = JSON.stringify(data);
    }
  }

  return fetch(url, params);
}

const jsonRequest = (url, method, data, header, errorCallback) => {
  return request(url, method, data, header)
    .then(resp => {
      if (resp.status === 401) {
        localStorage.removeItem('token');
      }
      return resp.json();
    }).then(json => {

      if (json.code < 200 || json.code >= 400 || json.violations) {
        json.type = 'error';
        if (json.violations) {
          json.message = json.violations[0].message;
        }
      }
      if (json.type === 'error') {
        return json;
      }

      return {
        data: json,
        type: 'success',
      };
  }, (resp) => {
      if (resp.status === 401) {
        localStorage.removeItem('token');
      }
      errorCallback();
    }
  );
}

const apiRequest = (url, method, data, header) => {
  url = 'api/v1/' + url;
  return jsonRequest(url, method, data, header, genericErrorHandler);
}

const cachedRequest = (url, method, data, header) => {
  const qry = data === undefined ? '' : data.join();
  const type = hashCode(qry);
  if (cache.hasOwnProperty(url) && cache[url].hasOwnProperty(type)) {
    return new Promise(resolve => resolve(cache[url][type]));
  }
  return apiRequest(url, method, data, header).then(resp => {
    if (!resp) {
      return {
        type: 'error',
        data: {
          code: 401
        }
      }
    }
    if (resp.type === 'success') {
      if (!cache[url]) {
        cache[url] = {};
      }
      cache[url][type] = resp;
    }
    return resp;
  });
}

const clearCache = (type, request) => {
  delete cache[type];
  return request;
}

export const loginCb = (resp) => {
  if (resp.code === 401 || resp.type === "error") {
    return {
      data: {
        message: "Authentication error",
      },
      type: 'error',
    };
  }
  localStorage.setItem("token", 'set');

  setLocalStorageObject("user", resp.data.data.user);
  while(!getLocalStorageObject('user'));
  setLocalStorageObject("company", resp.data.data.user.company);
  return {
    type: 'success',
  };
}

export const loginUser = (data) => {
  return function (dispatch) {
    return jsonRequest('authentication_token', 'POST', data, {}, () => errorHandler("Authentication error")).then(loginCb);
  }
}

const deleteRequest = (type, id, dispatch) => {
  return clearCache(type, request('api/v1/'+type+'/' + id, 'DELETE', '').then(data => {
    const resp = dispatch({
      type: data.ok ? 'success' : 'error',
      data: {
        code: data.status,
      },
    });
    if (!data.ok) {
      resp.data.message = "Error while deleting the user";
    }
    return resp;
  }));
}

export const signUp = (company) => () => apiRequest('companies', 'POST', company);
export const forgot = (email) => () => apiRequest('forgot', 'POST', {email: email});
export const resetToken = (id) => () => apiRequest('tokens/' + id, 'GET');
export const doReset = (data) => () => apiRequest('tokens/' + data.token, 'POST', data).then(loginCb);

//users
export const users = () => () => cachedRequest('users', 'GET');
export const createUser = (props) => () => clearCache('users', apiRequest('users', 'POST', props));
export const deleteUser = (id) => (dispatch) => deleteRequest('users', id, dispatch);
export const updateUser = (props) => () => clearCache('users', apiRequest('users/' + props.id, 'PATCH', props));

//assets
export const assets = () => () => apiRequest('assets', 'GET');
export const asset = (id) => () => apiRequest('assets/' + id, 'GET');
export const createAsset = (props) => () => clearCache('assets', apiRequest('assets', 'POST', props));
export const deleteAsset = (id) => (dispatch) => deleteRequest('assets', id, dispatch);
export const updateAsset = (props) => () => clearCache('assets', apiRequest('assets/' + props.id, 'PATCH', props));
export const cloneAsset = (props) => () => clearCache('assets', apiRequest('assets/' + props.id + '/clone', 'PATCH', []));
export const resetTokenAction = (props) => () => apiRequest('assets/' + props.id + '/reset', 'PATCH', props);

//logs
export const logs = (id, data) => () => apiRequest('files/'+ id +'/logs', 'GET', data);


export const clearAllCache = () => cache = {};
export const verify = (id, token) => () => apiRequest('users/' + id + '/verify', 'PATCH', {token: token});

//metrics
export const metricsCompany = () => () => cachedRequest('usage_companies', 'GET');
export const metricsDaily = (id) => () => cachedRequest('assets/' + id + '/metrics/daily', 'GET');
export const metricsHourly = (id) => () => cachedRequest('assets/' + id + '/metrics/hourly', 'GET');
export const metricsInstant = (id) => () => apiRequest('assets/' + id + '/metrics/instant', 'GET');

//stripe portal
export const portal = () => () => apiRequest('portal', 'GET');
