import React, {Component} from 'react';
import {connect} from 'react-redux';
import {logs, assets} from './actions/actions';
import DataTable from 'react-data-table-component';
import {errorHandler} from './actions/SwalActions';
import Select from 'react-select';
import moment from "moment-timezone";
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import TagList from './components/TagList';
// import IconButton from '@material-ui/core/IconButton';


import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { reducer as form } from 'redux-form';
import {
  connectRouter,
  routerMiddleware,
} from 'connected-react-router';
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();
const store = createStore(
  combineReducers({
    router: connectRouter(history),
    form,
    /* Add your reducers here */
  }),
  applyMiddleware(routerMiddleware(history), thunk)
);


const domain = "(https?://)?(www.)?(((?!\\-))(xn\\-\\-)?[a-z0-9\\-_]{0,61}[a-z0-9]{1,1}\\.)*(xn\\-\\-)?([a-z0-9\\-]{1,61}|[a-z0-9\\-]{1,30})\\.[a-z]{2,}";
const method = "HEAD|POST|GET|PUT|PATCH|OPTIONS|DELETE|CONNECT|TRACE";
const ipV6 = "(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))";
const ipV4 = "([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])";
const port = "(:[0-9]+)?";
const ipPort = new RegExp('(' + ipV6 +'|'+ ipV4 + ')' + port,'g');
const tag = /\[[a-zA-Z][^\]]+\]|\w+=\w+/g;

class Logs extends Component {

  perPage = 100;

  state = {
    show: false,
    logs: [],
    asset_id: '',
    file_id: null,
    files: [],
    tags: [],
    time_from: moment().subtract("5 minutes"),
    time_to: null,
    assetsList: [],
    loading: false,
    pagination_token: null,
    dtLoading: false,
    realtime: false,
    last_request: moment().unix(),
    filterInput: "",
  };

  handleTimezoneChange = (data) => {
    this.setState({
      user_timezone: data.value,
    });
  }

  handleClose = () => {
    this.setState({
      show: false
    });
  }

  componentDidMount = () => {
    // store.dispatch(logs()).then(resp => {
    //   this.props.isLoggedIn(resp);
    //   if (resp.type === 'error') {
    //     return false;
    //   }

    store.dispatch(assets()).then(resp => {
      this.props.isLoggedIn(resp);
      if (resp.type === 'error') {
        return false;
      }

      this.setState({
        assetsList: resp ? resp.data : [],
        time_from: moment().subtract(5, "minutes"),
      });
    });
  }

  handleDateFromChange = event => {
    this.setState({
      time_from: event,
    }, this.doFind);
  }
  handleDateToChange = event => {
    this.setState({
      time_to: event,
      realtime: event.value ? this.state.realtime : false,
    }, this.doFind);
  }
  handleChangeAsset = event => {
    const assetId = event.value;
    let files = [];
    if (assetId) {
      const asset = this.state.assetsList.find(asset => asset.id === assetId);
      files = asset ? asset.files : [];
    }
    this.setState({
      asset_id: assetId,
      files: files,
      file_id: null,
    }, this.doFind);
  }
  handleChangeLogFile = event => {
    this.setState({
      file_id: event.value
    }, this.doFind);
  }
  addTag = tag => {
    const tags = this.state.tags;
    tags.push(tag);
    this.setState({
      tags: tags
    }, this.loadNextPage);
  }
  removeTag = tag => {
    this.setState({
      tags: this.state.tags.filter(item => tag.value !== item.value || tag.type !== item.type),
    });
  }

  realtimeSearch = () => {
    const {file_id,  realtime, last_request } = this.state;
    if (file_id && realtime) {
      this.setState({
        dtLoading: true,
      });

      const params = {
        time_from: last_request,
      };
      this.setState({
        last_request: moment().unix(),
      });

      store.dispatch(logs(file_id, params)).then(resp => {
        if (resp) {
          this.props.isLoggedIn(resp);
          if (resp.type === "error") {
            this.setState({
              dtLoading: false,
            });
            errorHandler(resp.message);
          } else {
            const logs = this.makeLog(resp.data.data).reverse();
            //we work with max 5000 logs at a time
            const stateLogs = this.state.logs.slice(0, 5000);
            for (const i in logs) {
              let unshift = true;
              for (const j in stateLogs) {
                if (stateLogs[j].time < logs[i].time) {
                  break;
                }
                if (stateLogs[j].log === logs[i].log) {
                  unshift = false;
                  break;
                }
              }
              if (unshift) {
                stateLogs.unshift(logs[i]);
              }
            }

            this.setState({
              dtLoading: false,
              logs: stateLogs,
            }, () => setTimeout(this.realtimeSearch, 5000));
          }
        }
      });
    }
  }

  doFind = () => {
    const { time_from, time_to, file_id } = this.state;
    // const ips = [];
    // const ipPorts = [];
    // const theTags = [];
    // tags.forEach(tag => {
    //   switch (tag.type) {
    //     case 'ip': ips.push(tag.value); break;
    //     case 'ipport': ipPorts.push(tag.value); break;
    //     case 'tag': theTags.push(tag.value); break;
    //   }
    // });
    if (file_id) {
      this.setState({
        loading: true,
      });
      const params = {};
      // if (ips.length) {
      //   params.ips = ips;
      // }
      // if (ipPorts.length) {
      //   params.ipports = ipPorts;
      // }
      // if (theTags.length) {
      //   params.tags = theTags;
      // }
      if (time_from) {
        params.time_from = time_from.unix();
      }
      if (time_to) {
        params.time_to = time_to.unix();
      }
      this.setState({
        last_request: moment().unix(),
      });

      store.dispatch(logs(file_id, params)).then(resp => {
        if (resp) {
          this.props.isLoggedIn(resp);
          if (resp.type === "error") {
            this.setState({
              loading: false,
            });
            errorHandler(resp.message);
          } else {
            this.setState({
              loading: false,
              logs: this.makeLog(resp.data.data),
              pagination_token: resp.data.pagination_token
            }, () => {
              this.loadNextPage();
              this.realtimeSearch();
            });
          }
        }
      });
      return;
    }
    this.setState({
      logs: [],
      pagination_token: null,
    });
  }

  loadNextPage = () => {
    const { pagination_token, file_id, tags } = this.state;
    //we finished loading
    if (!pagination_token) {
      this.setState({
        dtLoading: false,
      });
      return;
    }
    this.setState({
      dtLoading: true,
    });
    const parameters = {
      pagination_token: pagination_token,
    };
    if (tags) {
      parameters.per_page = 1000;
    }
    store.dispatch(logs(file_id, parameters)).then(resp => {
      if (resp) {
        this.props.isLoggedIn(resp);
        if (resp.type === "error") {
          errorHandler(resp.message);
          this.setState({
            dtLoading: false,
          });
        } else {
          this.setState({
            logs: this.state.logs.concat(this.makeLog(resp.data.data)),
            pagination_token: resp.data.pagination_token
          }, () => {
            const pager = document.getElementById('pagination-next-page');
            if (pager === null || pager.disabled) {
              this.loadNextPage();
            } else {
              this.setState({
                dtLoading: false,
              });
            }
          });
        }
      }
    });
  }

  preloadPage = (page,total) => {
    if (Math.ceil((page + 1) * this.perPage) > total) {
      this.loadNextPage();
    }
  }

  getCurrentFile = () => {
    const { files, file_id } = this.state;
    return files.find((file) => file.id === file_id);
  }

  makeLog = (logs) => {
    const file = this.getCurrentFile();
    return logs.map(log => {
      log.tags = this.makeTags(log.log, file);
      return log;
    });
  };

  toggleRealtime = () => {
    this.setState({
      realtime: !this.state.realtime,
    }, this.realtimeSearch);
  };

  makeTags(item, file) {
    let tags = [];
    let matches = item.match(ipPort) || [];
    matches.forEach(item => {
      let lastIndex = item.lastIndexOf(':');
      if (lastIndex) {
        tags.push({
          type: 'ipport',
          value: item,
        });
      } else {
        lastIndex = item.length - 1;
      }

      tags.push({
        type: 'ip',
        value: item.substr(0, lastIndex),
      });
    });
    matches = item.match(tag) || [];
    matches.forEach(item => {
      tags.push({
        type: 'tag',
        value: item,
      });
    });
    matches = [...item.matchAll(domain)];
    matches.forEach(item => {
      if (item[0]) {
        tags.push({
          type: 'tag',
          value: item[0],
        });
      }
    });
    matches = item.match(method) || [];
    matches.forEach(item => {
      tags.push({
        type: 'tag',
        value: item,
      });
    });

    if (file && file.options && file.options.tags) {
      file.options.tags.forEach(tag => {
        matches = item.match(tag) || [];
        matches.forEach(item2 => {
          tags.push({
            type: 'custom_tag',
            value: item2,
          });
        });
      });
    }

    return tags;
  }

  handleFilterChange = e => {
    const value = e.target.value || undefined;
    this.setState({
      filterInput: value,
    }, this.doFind);
  }

  render() {

    const { time_from, time_to, assetsList, files, tags, loading, logs, dtLoading, realtime, filterInput } = this.state;
    const assetOptions = assetsList.map((item) => {return {value: item.id, label: item.name}});
    const fileOptions = files.map((item) => {return {value: item.id, label: item.path}});

    const data = logs.filter(item => {
      if (filterInput) {
        const input = filterInput.toLowerCase();
        if (input && item.log.toLowerCase().indexOf(input) === -1) {
            return false;
          }
      }
      for (const i in tags) {
        const tag = tags[i];
        let doReturn = true;
        for (const j in item.tags) {
          const itemTag = item.tags[j];
          if (tag.type === itemTag.type && tag.value === itemTag.value) {
            doReturn = false;
            break;
          }
        }
        if (doReturn) {
          return false;
        }
      }
      return true;
    });

    const columns = [
      {
        name: 'Time',
        sortable: false,
        width: "18em",
        cell: row => moment.unix(row.time).local().format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        name: 'Log',
        sortable: false,
        cell: row => <div>
          {row.log}
          <TagList onClick={this.addTag} data={row.tags}/>
        </div>,
      },
    ];
    return (
      <>
        <h2>Logs</h2>
            <form>
              <div className="row">
                <MuiPickersUtilsProvider utils={MomentUtils}>

                  <div className="col-md-3">
                    <div><label htmlFor="date_from">Date From:</label></div>
                    <DateTimePicker
                      id="date_from"
                      value={time_from}
                      onChange={this.handleDateFromChange}
                      maxDate={time_to ?? false}
                    />
                  </div>
                  <div className="col-md-3">
                    <div><label htmlFor="date_to">Date To:</label></div>
                    <DateTimePicker
                      id="date_to"
                      value={time_to}
                      onChange={this.handleDateToChange}
                      minDate={time_from}
                    />
                    {
                        time_to === null
                          ?
                          <div>
                            <label>Real time
                              <button type="button" className="btn btn-link shadow-none" onClick={this.toggleRealtime}>
                                <i className={"fa text-warning fa-toggle-" + (realtime ? "on" : "off")}/>
                              </button>
                            </label>
                          </div>
                          :
                          <div>
                            <button type="button" className="btn btn-link shadow-none" onClick={() => this.setState({
                              time_to: null,
                            }, this.doFind)}>
                              Clear
                            </button>
                          </div>
                    }
                  </div>
                </MuiPickersUtilsProvider>
                <div className="col-md-3">
                  <div><label htmlFor="asset">Asset:</label></div>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    name="asset_id"
                    id="asset_id"
                    options={assetOptions}
                    onChange={this.handleChangeAsset}
                  />
                </div>
                <div className="col-md-3">
                  <div><label htmlFor="asset">Log File:</label></div>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    name="file_id"
                    id="file_id"
                    options={fileOptions}
                    onChange={this.handleChangeLogFile}
                  />
                </div>

              </div>
              <div className="row">
                <div className="col-md-6">
                  <div><label htmlFor="search">Search:</label></div>
                  <input
                    className="form-control"
                    id="search"
                    value={filterInput}
                    onChange={this.handleFilterChange}
                    placeholder="Search"
                  />
                </div>
              </div>
              <TagList onClick={this.removeTag} data={tags}/>
            </form>
        {loading ?
          <div className="row">
            <div className="col-12 text-center">
              <div className="spinner-border text-center"/>
            </div>
          </div>
          :
          <div className="logs-datatable">
            <DataTable
              columns={columns}
              data={data}
              pagination={true}
              paginationPerPage={this.perPage}
              paginationIconFirstPage=""
              paginationIconLastPage=""
              onChangePage={this.preloadPage}
            />
            {dtLoading ?
              <div className="row">
                <div className="col-12 text-center">
                  <div className="spinner-border text-center"/>
                </div>
              </div>
              : ''}
          </div>
        }
      </>
    )
  }
}

export default connect((state) => ({
  show: state.show,
  logs: state.logs,
  asset_id: state.asset_id,
  file_id: state.file_id,
  files: state.files,
  time_from: state.time_from,
  time_to: state.time_to,
  assetsList: state.assetsList,
  loading: state.loading,
  dtLoading: state.dtLoading,
  realtime: state.realtime,
  filterInput: state.filterInput,
}))(Logs);
