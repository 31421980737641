import React from 'react';
export class ErrorBoundary extends React.Component {
  state = { hasError: false };

  componentDidCatch(error, info) {
    console.log(error, info);
    this.setState({ hasError: true });
  }

  render()
  {
    if (this.state.hasError) {
      return <h1 className="text-danger">Something went wrong. Please, try again later</h1>;
    }
    return this.props.children;
  }
}
