import React, {Component} from 'react';
import {connect} from 'react-redux';
import {loginUser} from './actions/actions';
import { errorHandler } from './actions/SwalActions';
import { Redirect } from 'react-router'


import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { reducer as form } from 'redux-form';
import {
  connectRouter,
  routerMiddleware,
} from 'connected-react-router';
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();
const store = createStore(
  combineReducers({
    router: connectRouter(history),
    form,
    /* Add your reducers here */
  }),
  applyMiddleware(routerMiddleware(history), thunk)
);

class Signup extends Component {

  state = {
    email: "demo",
    password: "demo",
    fireRedirect: false,
  };

  componentDidMount = () => {
    store.dispatch(loginUser(this.state)).then(data => {
      if (data.type === "error") {
        errorHandler(data.data.message);
      } else {
        this.setState({'fireRedirect': true});
      }
    });
  }

  render() {
    const { fireRedirect } = this.state
    return (
      <div>
        We are redirecting you to demo account
        {fireRedirect && (<Redirect to='/' />)}
      </div>
    )
  }
}

export default connect((state) => ({
  fireRedirect: state.fireRedirect
}))(Signup);
