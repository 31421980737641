import React, {Component} from 'react';
import {connect} from 'react-redux';
import { NavLink } from 'react-router-dom'
import {forgot} from './actions/actions';

import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { reducer as form } from 'redux-form';
import {
  connectRouter,
  routerMiddleware,
} from 'connected-react-router';
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();
const store = createStore(
  combineReducers({
    router: connectRouter(history),
    form,
    /* Add your reducers here */
  }),
  applyMiddleware(routerMiddleware(history), thunk)
);

class Forgot extends Component {

  state = {
    email: "",
    sent: false,
    error: '',
  };


  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = event => {
    event.preventDefault();

    store.dispatch(forgot(this.state.email)).then(data => {
      if (data.type === "error") {
        this.setState({
          error: data.message,
        });
      } else {
        this.setState({'sent': true});
      }
    });
  }

  render() {
    const { sent, error } = this.state
    return (
      <div>
        {sent ?
          <div>
            <h4>Your password reset email has been sent</h4>
            <p>You should receive an password reset link in an email sent to your registered email address shortly.
              Please follow the instructions in the email to reset the password for your account.</p>
          </div>
          :
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email address</label>
              <input
                className="form-control"
                name='email'
                id='email'
                placeholder='Enter email'
                value={this.state.email}
                onChange={this.handleChange}
                required
              />
              { error ?
                  <div className="alert alert-danger mt-1" role="alert">
                    {error}
                  </div>
                : ''}
            </div>
            <button type="submit" className="btn btn-primary mt-1">Restore</button>

          </form>
        }
        <div>
          <div>
            <NavLink to="/login" exact>
              Sign in
            </NavLink>
          </div>
        </div>
      </div>
    )
  }
}

export default connect((state) => ({
  sent: state.sent,
  error: state.error,
}))(Forgot);
