import React from 'react';
import { Redirect, Route } from 'react-router-dom'

const PublicRoute = function ({component: Component, ...rest}) {

  return (
    <Route
      {...rest}
      render={(props) => localStorage.getItem('token') === 'set'
        ? <Redirect to={{pathname: '/'}} />
        : <Component />
      }
    />
  );
}
export default PublicRoute;
