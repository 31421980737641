import * as React from 'react';
import { Footer } from './components/footer';
import { Header } from './components/header';

import { Links } from './components/links';

const MainLayout = ({ children }) => (
  <div>
    <Links/>
    <Header />
    <div className="mt-3 col-lg-10 offset-lg-1" id="content">
      {children}
    </div>
    <Footer />
  </div>
);


export default MainLayout;
