import {Component} from "react";
import {connect, DefaultRootState} from 'react-redux';
import React from "react";
import moment from "moment-timezone";
import {Button} from "react-bootstrap";
import {createStore, combineReducers, applyMiddleware, AnyAction} from 'redux';
import thunk from 'redux-thunk';
import {connectRouter, routerMiddleware} from "connected-react-router";
// @ts-ignore
import { reducer as form } from 'redux-form';
import {assets, cloneAsset, deleteAsset, getLocalStorageObject} from "./actions/actions";
import {errorHandler} from './actions/SwalActions';
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
// @ts-ignore
import {Link} from "react-router-dom";
const ROWS_PER_PAGE = 'assets/currentRowsPerPage';
const SORT_DIRECTION = 'assets/sortDirection';


let me: { roles: string | string[]; } | null = null;

const store = createStore(
    combineReducers({
        router: connectRouter(window.history),
        form,
    }),
    applyMiddleware(routerMiddleware(window.history), thunk)
);

type AssetProps = {
    isLoggedIn(resp: CustomAction): void;
}

interface AssetState extends DefaultRootState {
    assets: AssetEntity[],
    assetSaved: string,
}

export type AssetFileInput = "tail" | "stdin"

export type AssetFile = {
    "id"?: number,
    "path": string,
    "type": "regular" | "multiline" | "JSON" | "LTSV",
    "input": AssetFileInput,
    "options": {
        filters: string[];
        tags: string[];
        "time_key"?: string
        "time_format"?: string
    }
}

export type AssetEntity = {
    name: string;
    id?: number;
    key?: string;
    pinged?: number,
    files: AssetFile[],
}

interface CustomAction extends AnyAction {
    data?: AssetEntity;
    message?: string;
}

class Assets extends Component<AssetProps, AssetState> {
    private tokenRef: React.RefObject<unknown>;
    private newRef: React.RefObject<unknown>;
    private newTagRef: React.RefObject<unknown>;


    state: AssetState = {
        assets: [],
        assetSaved: '',
    };
    private interval: NodeJS.Timer | undefined;


    constructor(props: AssetProps) {
        super(props)
        this.tokenRef = React.createRef()
        this.newRef = React.createRef()
        this.newTagRef = React.createRef()
    }

    componentDidMount = () => {
        me = getLocalStorageObject('user');
        this.loadAssets();
        this.interval = setInterval(() => this.loadAssets(), 10000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    loadAssets = () => {
        const assetResp: CustomAction = assets() as unknown as CustomAction;
        store.dispatch(assetResp).then((resp: CustomAction) => {
            this.props.isLoggedIn(resp);
            const data = resp.data;//.map(asset => {
            if (resp.type === 'error') {
                return false;
            }
            this.setState({
                assets: Array.isArray(data) ? data : [],
            });
        });
    }

    handleClone = (asset: AssetEntity) => {
        const assetResp = cloneAsset({
            id: asset.id,
        }) as unknown as CustomAction;
        const action = store.dispatch(assetResp);
        action.then((data: CustomAction) => {
            if (!data || !data.data || !data.data.id) {
                errorHandler("Unexpected exception during updating the asset");
                return;
            }
            this.props.isLoggedIn(data);
            if (data.type === "error") {
                errorHandler(data.message);
                return;
            }
            const assets = this.state.assets;
            assets.push(data.data as AssetEntity);
            this.setState({
                assetSaved: 'Asset successfully cloned',
                assets: assets,
            });
            setTimeout(() => this.setState({assetSaved: ''}),2000);
        });
    }

    handleDelete = (asset: AssetEntity) => {
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure you want to delete this item? "+asset.name+"? This operation cannot be undone. ",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel!",
        }).then((submit) => {
            if (submit.isConfirmed) {
                const assetResp: CustomAction = deleteAsset(asset.id) as unknown as CustomAction;
                store.dispatch(assetResp).then((data: CustomAction) => {
                    if (data) {
                        this.props.isLoggedIn(data);
                        if (data.type === "error") {
                            errorHandler(data.message);
                        } else {
                            const assets = this.state.assets;
                            this.setState({
                                assets: assets.filter(item => item.id !== asset.id),
                                assetSaved: 'asset successfully deleted',
                            });
                        }
                    }
                });
            }
        });
    }

    render() {
        const columns = [
            {
                name: 'Name',
                selector: 'name',
                sortable: true,
            },
            {
                cell:(asset: AssetEntity) =>
                    asset.pinged && moment.now() / 1000 - asset.pinged < 300 ?
                        <div className="text-success"><i className="fa fa-circle"/> Connected</div>
                        :
                        asset.pinged && moment.now() / 1000 - asset.pinged < 300 ?
                            <div className="text-warning"><i className="fa fa-circle "/> Connection Issues</div>
                            :
                            <div className="text-danger"><i className="fa fa-circle "/> Not connected</div>
            },
            {
                cell:(asset: AssetEntity) =>
                    me && me.roles.indexOf("ROLE_ADMIN") === -1 ? '' :
                        <>
                            <Button  className="shadow-none"  onClick={() => this.handleClone(asset)} variant="link">
                                <i className="fa fa-clone"/>
                            </Button>
                            <Link to={`/asset/${asset.id}`} className="shadow-none btn btn-link">
                                <i className="fa fa-edit"/>
                            </Link>
                            <Button onClick={() => this.handleDelete(asset)} variant="link">
                                <i className='fa fa-trash text-danger '/>
                            </Button>
                        </>,
                name:
                    me && me.roles.indexOf("ROLE_ADMIN") === -1 ? '' :
                        <Link to="/asset/create" className="shadow-none btn btn-primary" role="button">
                            Add Asset
                        </Link>,
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
            },
        ];

        const perPageStored = localStorage.getItem(ROWS_PER_PAGE);
        const perPage = perPageStored ? parseInt(perPageStored) : 10;
        return (
            <div>
                <h2>Assets </h2>

                { this.state.assets.length === 0
                    ?
                    <div className="col-12 text-center">
                        <Link to="/asset/create" className="shadow-none">
                            Start with adding Asset here
                        </Link>
                    </div>
                    : <DataTable
                        columns={columns}
                        data={this.state.assets}
                        className="overflow-hidden"
                        pagination={true}
                        onChangeRowsPerPage={(currentRowsPerPage) => {
                            localStorage.setItem(ROWS_PER_PAGE,currentRowsPerPage.toString());
                        }}
                        paginationPerPage={ perPage }
                        onSort={(field, direction) => {
                            localStorage.setItem(SORT_DIRECTION,direction)
                        }}
                        defaultSortAsc={localStorage.getItem(SORT_DIRECTION) !== 'desc'}
                    />
                }
            </div>
        );
    }
}
export default connect((state: AssetState) => ({
    assets: state.assets,
    assetSaved: state.assetSaved,
}))(Assets);
