import React, {useState} from 'react';
import { NavLink, Redirect } from 'react-router-dom'
import {clearAllCache, getLocalStorageObject, portal} from '../../actions/actions'
import 'bootstrap'
import {applyMiddleware, combineReducers, createStore} from "redux";
import {connectRouter, routerMiddleware} from "connected-react-router";
import {reducer as form} from "redux-form";
import thunk from "redux-thunk";


import { createBrowserHistory } from 'history';
import {Image} from "react-bootstrap";
const history = createBrowserHistory();
const store = createStore(
  combineReducers({
    router: connectRouter(history),
    form,
    /* Add your reducers here */
  }),
  applyMiddleware(routerMiddleware(history), thunk)
);

export function Header() {
  const [authed, logOut] = useState(true);
  const [portalUrl, pay] = useState('');

  const me = getLocalStorageObject('user');

  const handleSignOut = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('company');
    clearAllCache();
    logOut(() => false);
    window.location.reload(false);
  }

  const payments = () => {
    let portalUrl = sessionStorage.getItem('portal');
    if (!portalUrl) {
      store.dispatch(portal()).then(resp => {
        if (!resp.data.url) {
          return;
        }
        sessionStorage.setItem('portal', resp.data.url);
        pay(() => resp.data.url);
      });
      return;
    }
    pay(() => portalUrl);
  }

  return (
    portalUrl ? window.location.href = portalUrl : (
    authed === true && localStorage.getItem('user') ?
      <header className="app-header">
          <nav className="navbar navbar-expand-lg navbar-dark bg-primary px-4">
              <a className="navbar-brand" href="/"><Image
                  src="/5c0635e8e2e30100229a32a6_optimized_101.png"
                  height="46" alt=''/></a>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent-3" aria-controls="navbarSupportedContent-3"
                      aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"/>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent-3">
                  <ul className="navbar-nav mr-auto">
                      <li className="nav-item ">
                        <NavLink to="/" exact activeClassName="active" className="nav-link">
                          <i className="fa fa-tachometer-alt"/>  Dashboard
                        </NavLink>
                      </li>
                      <li className="nav-item ">
                        <NavLink to="/logs" exact activeClassName="active" className="nav-link">
                          <i className="fa fa-file"/>  Logs
                        </NavLink>
                      </li>
                      <li className="nav-item ">
                        <NavLink to="/assets" exact activeClassName="active" className="nav-link">
                          <i className="fa fa-cube"/>  Assets
                        </NavLink>
                      </li>
                      <li className="nav-item ">
                        <NavLink to="/users" exact activeClassName="active" className="nav-link">
                          <i className="fa fa-users"/>  Users
                        </NavLink>
                      </li>
                    {me.roles.indexOf("ROLE_ADMIN") === -1 && !me.company.aws_customer_id ? '' :
                      <li className="nav-item ">
                        <a onClick={payments} href="#" className="nav-link">
                          <i className="fa fa-users"/> Payments
                        </a>
                      </li>
                    }
                  </ul>
                  <ul className="navbar-nav ms-auto nav-flex-icons">
                      <li className="nav-item">
                          <NavLink to="/" exact activeClassName="active" className="nav-link" onClick={handleSignOut}>
                            <i className="fa fa-sign-out-alt"/>  Sign-out
                          </NavLink>
                      </li>
                  </ul>
              </div>
          </nav>
      </header>
    : <Redirect to={{pathname: '/login'}} />)
  );
}
