import {Component, FormEvent} from "react";
import {connect, DefaultRootState} from 'react-redux';
import React from "react";
import {Alert, Button} from "react-bootstrap";
import {createStore, combineReducers, applyMiddleware, AnyAction} from 'redux';
import thunk from 'redux-thunk';
import {connectRouter, routerMiddleware} from "connected-react-router";
// @ts-ignore
import { reducer as form } from 'redux-form';
import {
    asset, createAsset,
    getLocalStorageObject, resetTokenAction,
    updateAsset
} from "./actions/actions";
import {errorHandler} from './actions/SwalActions';
import Swal from "sweetalert2";
import {AssetEntity, AssetFile, AssetFileInput} from "./Assets";
// @ts-ignore
import Select from "react-select";
import { ENTRYPOINT } from './config/entrypoint'
const defaultTimeFormat = '[A-Z][a-z][a-z] \\d\\d \\d\\d:\\d\\d:\\d\\d';

let me: { roles: string | string[]; } | null = null;

const store = createStore(
    combineReducers({
        router: connectRouter(window.history),
        form,
    }),
    applyMiddleware(routerMiddleware(window.history), thunk)
);

type AssetProps = {
    isLoggedIn(resp: CustomAction): void;
    props: {
        match: {
            params: {
                id: number,
            }
        }
    }
}

type AssetTag = {
    name: string,
    value: string,
}

interface AssetState extends DefaultRootState {
    assetSaved: boolean;
    asset: AssetEntity,
    updated: boolean,
    asset_key_hidden: boolean,
    asset_key_copied: boolean,
    instructionsShow: boolean,
}

interface CustomAction extends AnyAction {
    data: AssetEntity;
    message?: string;
}

const inputOptions = [{value: "stdin", label: "stdin (ie docker log driver)",},{value: "tail", label: "Tail (ie file tail)",},];


class Asset extends Component<AssetProps, AssetState> {
    private readonly newTagRef: React.RefObject<HTMLDivElement>;
    private readonly updatedMessageRef: React.RefObject<HTMLDivElement>;
    public state: AssetState = {
        asset: {
            name: "",
            files: [
                {
                    "path": "",
                    "type": "regular",
                    "input": "stdin",
                    "options": {
                        tags: [],
                        filters: [],
                    }
                },
            ],
        },
        instructionsShow: false,
        assetSaved: false,
        updated: false,
        asset_key_hidden: true,
        asset_key_copied: false,
    };
    private readonly id: number;

    constructor(props: AssetProps) {
        super(props)
        this.id = props.props.match.params.id;
        this.newTagRef = React.createRef();
        this.updatedMessageRef = React.createRef();
    }

    componentDidMount = () => {
        me = getLocalStorageObject('user');
        this.loadAsset();
    }

    download = (e: { preventDefault: () => void; }) => {
        if (this.state.updated) {
            Swal.fire("Error", "Please save the asset first", "error");
            e.preventDefault();
        }
    }

    loadAsset = () => {
        if (!this.id) {
            return;
        }
        const assetResp: CustomAction = asset(this.id) as unknown as CustomAction;
        store.dispatch(assetResp).then((resp: CustomAction) => {
            this.props.isLoggedIn(resp);
            if (resp.type === 'error' || !resp.data) {
                return false;
            }
            this.setState({
                asset: resp.data,
            });
        });
    }

    handleSave = (e: FormEvent) => {
        e.preventDefault();
        let {asset} = this.state;
        const fn = asset.id ? updateAsset(asset) : createAsset(asset);
        store.dispatch(fn as unknown as AnyAction).then((asset: CustomAction) => {
            setTimeout(() => this.setState({assetSaved: false}),2000);
            this.setState({
                assetSaved: true,
                asset: asset.data,
            });
            if (this.updatedMessageRef.current) {
                this.updatedMessageRef.current.scrollIntoView({behavior: 'smooth'});
            }
        }).catch((message: any) => errorHandler(message));
    }

    handleAssetChange = (asset: AssetEntity) => {
        this.setState({asset: asset});
    }

    handleReset = () => {
        let {asset} = this.state;
        if (!asset) return;
        store.dispatch(resetTokenAction({
            id: asset.id,
        }) as unknown as AnyAction).then((data: CustomAction) => {
            asset.key = data.data.key;
            this.setState({
                asset: asset,
            })
        });
    }

    handleChange = () => this.setState({
        updated: true,
    });

    renderInstructions = () => {
        const {asset, instructionsShow} = this.state;
        return <div>
            <div className="row d-inline-block">
                <a className="pe-0 btn btn-link deployment-info-btn d-inline" onClick={() => {
                    this.setState({
                        instructionsShow: !instructionsShow,
                    });
                    return false;
                }}>
                    Deployment instructions
                </a>
                <span className="align-middle d-inline">or</span>
                <a className="align-middle d-inline" target="_blank"  rel="noopener noreferrer" href={ENTRYPOINT + "/api/v1/assets/certificates/" + asset.key}
                   onClick={this.download}
                >
                    Download FluentBit Config
                </a>
            </div>

            { instructionsShow ?
                <ul className={'mt-2'}>
                    <li className="row col-12"><h6>1. <a href="https://docs.docker.com/get-docker/" target="_blan" rel="noopener noreferrer">Install docker</a></h6></li>
                    <li className="row col-12">
                        <h6>2. Run:</h6>
                        <div className="col-12">
                            <code className="text-info">
                                docker pull seccubi/logger && docker run -p 127.0.0.1:24224:24224  --restart=always {asset.files.filter(n => !n.id || (n.id && n.path)).map((n) => {
                                if (n.path.indexOf("/") === -1) {
                                    return '';
                                }
                                return ' -v ' + n.path + ':' + n.path + ':ro ' ;
                            })} -it seccubi/logger {asset.key} -d
                            </code>
                        </div>

                    </li>
                    <li className="row col-12">
                        <div>3. To log from your docker containers update your containers as following:</div>
                        <h6 className="col-12">With docker run:</h6>
                        <div className="col-12">
                            <pre>
                              <code className="text-info">
                                docker run --log-driver=fluentd --log-opt tag=&lt;container label set above&gt; -it &lt;your image&gt;
                              </code>
                            </pre>
                        </div>

                        <h6  className="col-12">With docker compose:</h6>

                        <div className="col-12">
                            <pre>
                              <code className="text-info">
                              {'services: \n' +
                                  '  ***\n' +
                                  '  image: <your image>\n' +
                                  '  logging:\n' +
                                  '    driver: fluentd\n' +
                                  '    options:\n' +
                                  '      tag: <docker container label set above>'
                              }
                              </code>
                            </pre>
                        </div>
                    </li>
                </ul>
                : ''}
        </div>
    }

    renderFiles = () => {
        const {asset} = this.state;
        return <div>
        <div className="row">
            <div className="col-6">
                <label>Asset Files:</label>
            </div>
            <div className="col-6 text-end">
                <Button onClick={() => {
                    asset.files.push({
                        "path": "",
                        "type": "regular",
                        "input": "stdin",
                        "options": {
                            "tags": [],
                            "filters": [],
                        }
                    });
                    this.setState({
                        asset: asset,
                    });
                    if (this.newTagRef.current) {
                        this.newTagRef.current.scrollIntoView({behavior: 'smooth'});
                    }
                }} variant="link">
                    <i className='fa fa-plus text-primary text-right'/>
                </Button>
            </div>
        </div>
        <div className="tags-container">
            {asset.files.map((n, i) => {
                return <div key={i}>
                    <div className="row">
                        <div className="col-11">
                            <div className="form-group">
                                <label>Input:</label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable={false}
                                    name="input"
                                    options={inputOptions}
                                    onChange={(e: { value: AssetFileInput; }) => {
                                        asset.files[i].input = e.value;
                                        this.handleAssetChange(asset);
                                    }}
                                    defaultValue={inputOptions.find((option) => option.value === n.input)}
                                />
                            </div>
                        </div>
                        <div className={"col-1"}>
                            <Button onClick={() => {
                                asset.files.splice(i, 1)
                                this.setState({
                                    asset: asset,
                                });
                            }} variant="link">
                                <i className='fa fa-trash text-danger mt-4 '/>
                            </Button>
                        </div>
                    </div>

                    <div className="form-group">
                        <label>{n.input === 'stdin' ? 'Docker container name' : "Absolute file path"}:</label>
                        <input
                            className="form-control"
                            placeholder={n.input === 'stdin' ? 'container-name' : "/Absolute/path/to/my.file"}
                            key={i}
                            value={n.path}
                            required
                            onChange={e => {
                                asset.files[i].path = e.target.value;
                                this.handleAssetChange(asset);
                            }}
                        />
                    </div>

                    <div className="row mt-1">
                        <div className="col-6">
                            <div className="form-check">
                                <input className="form-check-input" checked={n.type === "multiline"} type="checkbox"
                                       value=""
                                       id={"multiline" + i}
                                       onChange={e => {
                                           asset.files[i].type = e.target.checked ? 'multiline' : 'regular';
                                           this.handleAssetChange(asset);
                                       }}
                                />
                                <label className="form-check-label" htmlFor={"multiline" + i}>
                                    Is Multiline
                                </label>
                            </div>
                        </div>
                    </div>

                    {n.type === 'multiline' || n.type === 'JSON' || n.type === 'LTSV' ?
                        <div className="form-group">
                            <label>First Line Time Format:</label>
                            <input className="form-control"
                                   value={n.options.time_format || defaultTimeFormat}
                                   onChange={e => {
                                       asset.files[i].options.time_format = e.target.value;
                                       this.handleAssetChange(asset);
                                   }}
                            />
                        </div>
                        : ''}

                        {this.outputTag(asset, n, i)}
                        {this.filterTag(asset, n, i)}

                        <hr/>
                </div>
            })}
            <div ref={this.newTagRef}></div>
        </div>
        </div>
    }

    outputTag = (asset: AssetEntity, n: AssetFile, i: number) => {
        return <div className="row mt-1">
            <div className="col-12">
                { n.type !== 'JSON' && n.type !== 'LTSV' ?
                    <div className="form-group">
                        <Button onClick={() => {
                            asset.files[i].options.tags.push("");
                            this.setState({
                                asset: asset,
                            });
                        }} variant="link">
                            <i className='fa fa-plus text-primary '/>
                        </Button>
                        <label>Output tags:</label>
                        {n.options.tags.map((o, j) => <div className="row" key={j}>
                                <div className="col-11 mt-1">
                                    <input className="form-control" placeholder={"regexp to match as a custom tag"}
                                           value={o || ''}
                                           onChange={e => {
                                               asset.files[i].options.tags[j] = e.target.value;
                                               this.setState({
                                                   asset: asset,
                                               });
                                           }}
                                    />
                                </div>
                                <div className="col-1">
                                    <Button onClick={() => {
                                        asset.files[i].options.tags.splice(j, 1)
                                        this.setState({
                                            asset: asset,
                                        });
                                    }} variant="link">
                                        <i className='fa fa-trash text-danger '/>
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div> : "" }
            </div>
        </div>;
    }

    filterTag = (asset: AssetEntity, n: AssetFile, i: number) => {
        return <div className="row mt-1">
            <div className="col-12">
                { n.type !== 'JSON' && n.type !== 'LTSV' ?
                    <div className="form-group">
                        <Button onClick={() => {
                            asset.files[i].options.filters.push("");
                            this.setState({
                                asset: asset,
                            });
                        }} variant="link">
                            <i className='fa fa-plus text-primary '/>
                        </Button>
                        <label>Filter out patterns:</label>
                        {n.options.filters.map((o, j) => <div className="row" key={j}>
                                <div className="col-11 mt-1">
                                    <input className="form-control" placeholder={"regexp to ignore strings"}
                                           value={o || ''}
                                           onChange={e => {
                                               asset.files[i].options.filters[j] = e.target.value;
                                               this.setState({
                                                   asset: asset,
                                               });
                                           }}
                                    />
                                </div>
                                <div className="col-1">
                                    <Button onClick={() => {
                                        asset.files[i].options.filters.splice(j, 1)
                                        this.setState({
                                            asset: asset,
                                        });
                                    }} variant="link">
                                        <i className='fa fa-trash text-danger '/>
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div> : "" }
            </div>
        </div>;
    }

    renderKey = () => {
        const {asset, asset_key_hidden, asset_key_copied} = this.state;
        const key = asset ? asset.key : null;
        if (!key) return "";
        return <div className="form-group">
                <label htmlFor="asset-name">Token:</label>
                <div className={"mt-1 bg-secondary "+ (asset_key_hidden ? "text-secondary" : "text-light")}>
                    {key}
                </div>
                <div className="mt-1">
                    <Button onClick={this.handleReset}>Reset Token</Button>
                    <Button className={"mx-1 " + (asset_key_copied ? "bg-success" : "bg-primary")} onClick={() => {
                        navigator.clipboard.writeText(key).then(() => {
                            this.setState({
                                asset_key_copied: true,
                            });
                            setTimeout(() => this.setState({
                                asset_key_copied: false,
                            }), 2000);
                        });
                    }}>{asset_key_copied ? "Copied!" : "Copy Token"}</Button>
                    <Button onClick={() => this.setState({
                        asset_key_hidden: !asset_key_hidden,
                    })}>{asset_key_hidden ? "Show Token" : "Hide Token"}</Button>
                </div>
            </div>
    }

    render() {
        const {assetSaved, asset} = this.state;
        return (
            <div>
                {!asset && this.id ?
                    <div className="col-12 text-center">
                        <div className="spinner-border text-center"/>
                    </div>
                    :
                    <div ref={this.updatedMessageRef}>
                        <h2>Asset: {asset ? asset.name : ""}</h2>
                        <Alert show={assetSaved} variant="success">
                            Asset has been { asset.id ? "updated" : "created" }.
                        </Alert>
                        <form onSubmit={this.handleSave}>
                            <div className="form-group">
                                <label htmlFor="asset-name">Name:</label>
                                <input
                                    placeholder="Name your asset"
                                    className="form-control"
                                    id="asset-name"
                                    name="asset_name"
                                    required
                                    value={asset ? asset.name : ""}
                                    onChange={e => {
                                        asset.name = e.target.value;
                                        this.handleAssetChange(asset);
                                    }}
                                />
                            </div>
                            {this.renderKey()}
                            <hr />
                            {this.renderFiles()}

                            <div className={'row'}>
                                <div>
                                    <Button variant="primary" type="submit" className={'float-end'}>
                                        {asset.id === null ? 'Create' : 'Save'}
                                    </Button>
                                </div>
                            </div>
                        </form>

                        {asset.id ? this.renderInstructions() : ""}

                    </div>
                }
            </div>
        );
    }

}
export default connect((state: AssetState) => ({
    assets: state.asset,
    assetSaved: state.assetSaved,
    instructionsShow: state.instructionsShow,
    updated: state.updated,
    asset_key_hidden: state.asset_key_hidden,
    asset_key_copied: state.asset_key_copied,
}))(Asset);
