import * as React from 'react';
import { Links } from './components/links';

export default function LoginLayout(props) {
  const { children } = props;

  return (
    <div>
      <Links/>
        <div className="app-content mt-5 ">
            <main className="container col-lg-4 col-offset-lg-4">
                <div>
                    <div className="col-12 text-center">
                        <span className="logo-50 images-sprite icons-sprite icons-logo">&nbsp;</span>
                    </div>
                </div>
                <div className="container pt-5">
                    {children}
                </div>
            </main>
        </div>
    </div>
  );
}
