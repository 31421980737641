import React, {useState} from 'react';
import { Redirect, Route } from 'react-router-dom'
import { clearAllCache } from './actions/actions'
import Swal from 'sweetalert2'

const PrivateRoute = ({component: Component, ...rest})  => {
  const [out, logOut] = useState(!Boolean(localStorage.getItem('token')));
  const company = localStorage.getItem('company');
  const inactive = company ? !Boolean(JSON.parse(company).active) : false;

  if (inactive) {
    Swal.fire({
      title: "Account disabled",
      html: "Your account is inactive, please contact <a href=\"mailto:support@seccubi.com\">support@seccubi.com</a> for more information",
      showCloseButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
    });
  }

  function checkLoggedIn(data) {
    if (data !== undefined && data.code !== undefined && data.code === 401) {
      localStorage.removeItem('token');
      clearAllCache();
      logOut(() => true);
    }
  }

  return (
    <Route
      {...rest}

      render={(props) => out
        ? <Redirect to={{pathname: '/login', state: {from: props.location}}} />
        : (inactive ? <div></div> : <Component props={props} isLoggedIn={checkLoggedIn} />)
      }
    />
  );
}

export default PrivateRoute;
