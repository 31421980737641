import React, {Component} from 'react';
import {connect} from 'react-redux';
import { NavLink } from 'react-router-dom'
import {loginUser} from './actions/actions';
import { errorHandler } from './actions/SwalActions';
import { Redirect } from 'react-router'


import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { reducer as form } from 'redux-form';
import {
  connectRouter,
  routerMiddleware,
} from 'connected-react-router';
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();
const store = createStore(
  combineReducers({
    router: connectRouter(history),
    form,
    /* Add your reducers here */
  }),
  applyMiddleware(routerMiddleware(history), thunk)
);

class Signup extends Component {

  state = {
    email: "",
    password: "",
    fireRedirect: false,
  };


  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = event => {
    event.preventDefault();

    store.dispatch(loginUser(this.state)).then(data => {
      if (data.type === "error") {
        errorHandler(data.data.message);
      } else {
        this.setState({'fireRedirect': true});
      }
    });
  }

  render() {
    const location = (this.props.location && this.props.location.state && this.props.location.state.from) || '/';
    const { fireRedirect } = this.state
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email address</label>
            <input
              className="form-control"
              name='email'
              id='email'
              placeholder='Enter email'
              value={this.state.email}
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              className="form-control"
              name='password'
              id='password'
              placeholder='Password'
              value={this.state.password}
              onChange={this.handleChange}
              required
              type="password"
            />
          </div>
          <button type="submit" className="btn btn-primary mt-1">Sign in</button>

        </form>
        <div>
          <div>
            <NavLink to="/forgot" exact>
              Forgot password?
            </NavLink>
          </div>
          <div>
            Need an account? <NavLink to="/signup" exact>
              Sign up
            </NavLink>
          </div>
        </div>
        {fireRedirect && (<Redirect to={location} />)}
      </div>
    )
  }
}

export default connect((state) => ({
  fireRedirect: state.fireRedirect
}))(Signup);
